@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	scrollbar-width: thin;
	scrollbar-color: #10b981 #f0f4f8;
}

*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #f0f4f8;
}

*::-webkit-scrollbar-thumb {
	background-color: #aaafae;
	border-radius: 20px;
	border: 3px solid #f0f4f8;
}

@layer base {
	html {
		font-family: "Roboto", sans-serif;
	}
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
